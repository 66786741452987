import React from "react";
import AbstractComponent from "../AbstractComponent/AbstractComponent";
import { HorizontalBar } from "react-chartjs-2";
import i18n from "utils/i18n";
import { withTranslation } from "react-i18next";

const legendOpts = {
  display: false,
};

class Bar extends AbstractComponent {
  formatOptions(max) {
    return {
      halfCornerRadius: true,
      barIndicatorPlugin: true,
      animation: false,
      maintainAspectRatio: true,
      responsive: false,
      layout: {
        padding: {
          right: 0,
          top: 10,
          bottom: 0,
          left: 1,
        },
      },
      scales: {
        xAxes: [
          {
            ticks: {
              display: false,
              beginAtZero: true,
              max: max,
            },
            gridLines: {
              display: false,
              lineWidth: 0,
              drawBorder: false,
              drawOnChartArea: false,
            },
          },
        ],
        yAxes: [
          {
            categoryPercentage: this.props.barSize || 0.6,
            scaleLabel: {
              display: false,
            },
            gridLines: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              display: this.props.showTicks,
              fontColor: this.props.ticksColor || "#666",
              fontStyle: this.props.fontStyle,
              fontSize: this.props.fontSize,
            },
          },
        ],
      },
      horizontalBarLabel: {
        display: this.props.horizontalBarLabel,
      },
      horizontalBarDataLabel: {
        display: true,
        fontSize: 12,
        paddingLeft: 0,
        paddingTop: 7,
        align: "auto",
        fontColor: "black",
        fontStyle: "bold",
        formatter: (value) => value + "h",
      },
      secondBarPlugin: this.props.styleSecondbar,
      tooltips: this.defineTooltip(),
    };
  }

  defineTooltip() {
    if (this.props.displayTooltip) {
      return {
        enabled: true,
        mode: "index",
        displayColors: false,
        callbacks: {
          title: (tooltipItem, data) => {
            return "";
          },
          beforeLabel: (tooltipItem, data) => {
            return `${i18n.t("labels.default", { ns: "common" })}: ${data.datasets[tooltipItem.datasetIndex].data2[tooltipItem.index]}h`;
          },
          label: (tooltipItem, data) => {
            return `${i18n.t("labels.real", { ns: "common" })}: ${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]}h`;
          },
        },
      };
    }

    return {
      enabled: false,
      displayColors: false,
      mode: "index",
      intersect: false,
      style: { marginLeft: 0 },
    };
  }

  formatData(canvas, data) {
    let ctx = canvas.getContext("2d");
    let azulEscuro = ctx.createLinearGradient(
      0,
      canvas.clientHeight,
      canvas.clientWidth,
      canvas.clientHeight / 5,
    );
    azulEscuro.addColorStop(0.0, "rgba(43, 70, 102, 1.000)");
    azulEscuro.addColorStop(1, "rgba(105, 139, 178, 1.000)");
    return {
      labels: [data.label],
      datasets: [
        {
          backgroundColor: azulEscuro,
          hoverBackgroundColor: azulEscuro,
          borderWidth: 0,
          data: [data.padrao],
          data2: [data.real],
          indicatorBackground: [data.indicatorBackground],
          indicatorColor: [data.indicatorColor],
        },
      ],
    };
  }

  render() {
    let data = (canvas) => this.formatData(canvas, this.props);
    let options = this.formatOptions(
      this.props.padrao > this.props.real ? this.props.padrao : this.props.real,
    );

    console.log(this.props);
    return (
      <div style={this.props.style}>
        <HorizontalBar
          height={this.props.height || 80}
          width={this.props.width || 300}
          ref="chart"
          redraw={true}
          data={data}
          options={options}
          legend={legendOpts}
        />
        {this.props.frequency && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginTop: 8,
            }}
          >
            <div
              style={{
                padding: 4,
                borderRadius: 4,
                color: "#5B5B5B",
                fontWeight: 700,
                backgroundColor: "#e8e8e8",
                marginBottom: 8,
              }}
              title={this.props.t("labels.setupFrequency")}
            >
              {this.props.frequency}
            </div>
            <div
              style={{ fontWeight: 700, color: this.props.indicatorBackground }}
              title={this.props.t("labels.setupPerformance")}
            >
              {this.props.setupPerformance}%
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation("oee")(Bar);
